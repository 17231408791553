import { ClientId, P, PBold } from "@fm-frontend/uikit";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { useSelector } from "hooks";
import React from "react";
import styled from "styled-components";

export const CustomOption = styled(SingleDropdown.Option)`
    span {
        display: none;
    }
`;

export const ActionCustomOption = styled(CustomOption)`
    ${P} {
        color: ${(p) => p.theme.colors.ui52};
    }
`;

export const LogoutIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 3C12.4477 3 12 3.44772 12 4L12 12C12 12.5523 12.4477 13 13 13C13.5523 13 14 12.5523 14 12L14 4C14 3.44771 13.5523 3 13 3ZM8 5L11 8L8 11L8 9L2.8 9C2.35817 9 2 8.55228 2 8C2 7.44772 2.35817 7 2.8 7L8 7L8 5Z"
            fill="#243034"
            fillOpacity="0.32"
        />
    </svg>
);

const Container = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    height: 40px;
    padding: 0 6px 0 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: 8px;
    background-color: ${(p) => (p.active ? p.theme.colors.ui12 : p.theme.colors.ui8)};

    :hover {
        background-color: ${(p) => p.theme.colors.ui12};
    }

    :focus-visible {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.r},
            inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
    }
`;
const UserInfo = styled(PBold)`
    user-select: none;
    display: flex;
    gap: 4px;
`;

export const ProfileDropdownTrigger = React.forwardRef<HTMLDivElement, { active: boolean }>(
    (props, ref) => {
        const username = useSelector((state) => state.authentication.clientData?.username);
        const clientId = useSelector((state) => state.authentication.clientData?.clientId);

        return (
            <Container {...props} ref={ref} tabIndex={0}>
                <UserInfo>
                    {username} {clientId && <ClientId id={clientId} tooltip={null} />}{" "}
                </UserInfo>
            </Container>
        );
    },
);
