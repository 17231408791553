import { P, PBold } from "@fm-frontend/uikit";
import { isSubaccountCp } from "feature/counterparties/utils";
import { Tabs } from "feature/settlements/const";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { usePrimeBrokerViewTypeChange } from "hooks/usePrimeBrokerViewTypeChange";
import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Notification, NotificationMessageType } from "services/notificationsService";
import { actionChannel } from "store/actionChannel";
import { useIsPrimeBrokerUser, useIsSubaccountUser } from "store/hooks";
import { notificationsReadRequest } from "../notifications.store";
import { ReactComponent as CheckIcon } from "./check.svg";
import { Container, ContentWrapper, Status, StatusWrapper, Timestamp } from "./styled";
import { getTimeAgoInfo } from "./utils";

// there should be uses ROUTES, but due to module issue, there are string literals
const NOTIFICATION_DETAILS_URL_TEMPLATES: Record<NotificationMessageType, string> = {
    [NotificationMessageType.INITIAL_MARGIN]: "/riskmanagement",
    [NotificationMessageType.RESTRICTED_TRADING]: "/riskmanagement",
    [NotificationMessageType.LIQUIDATION]: "/riskmanagement",
    [NotificationMessageType.LIMIT_SET]: "/riskmanagement",
    [NotificationMessageType.MUTUAL_LIMIT_SET]: "/riskmanagement",
    [NotificationMessageType.SUBACCOUNT_LIMIT_SET]: "/riskmanagement",

    [NotificationMessageType.INCOMING_SETTLEMENT]: `/transactions?cp={cpId}&asset={currencyName}&tab=${Tabs.ALL}`,
    [NotificationMessageType.COMMITTED_TRANSACTION]: `/transactions?cp={cpId}&asset={currencyName}&tab=${Tabs.ALL}`,

    [NotificationMessageType.DAILY_REPORT_SENT]: "",
    [NotificationMessageType.MONTHLY_REPORT_SENT]: "",

    [NotificationMessageType.ADDRESSES_NEED_CONFIRMATION]: `/addresses/shared/crypto`,
    [NotificationMessageType.ADDRESSES_CONFIRMED]: `/addresses/your/crypto`,
};

// It's needed to guarantee that timeAgoInfo will be new
const CORRELATION_DELTA = 100;

function setFilters(template: string, params: Record<string, any>): string {
    return template.replace(/{(\w+)}/g, (match, key) => {
        return params.hasOwnProperty(key) ? params[key] : "";
    });
}

const markNotificationAsRead = (id: number, isRead: boolean) => {
    if (!isRead) {
        actionChannel.put(notificationsReadRequest({ ids: [id] }));
    }
};

type NotificationItemProps = {
    notification: Notification;
    closePopover: () => void;
};

export const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
    const { id, messageType, content, isRead, metadata, sentAt } = notification;

    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isSubaccountUser = useIsSubaccountUser();
    const changePrimeBrokerViewType = usePrimeBrokerViewTypeChange();
    const { getCpType } = useCpInfoHelpers();

    const history = useHistory();
    const [, forceUpdate] = useState(0);
    const { label: timeAgoString, updateIn } = getTimeAgoInfo(sentAt);
    const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout(() => {
            forceUpdate((i) => i + 1);
        }, updateIn + CORRELATION_DELTA);
    }, [timeAgoString]);
    useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, []);

    const Message = isRead ? P : PBold;
    const handleContentClick = (e: SyntheticEvent) => {
        e.stopPropagation();

        markNotificationAsRead(id, isRead);

        const detailsPageUrlTemplate = NOTIFICATION_DETAILS_URL_TEMPLATES[messageType] ?? "";
        const detailsPageUrl = setFilters(detailsPageUrlTemplate, metadata);

        if (!detailsPageUrl) {
            return;
        }

        if (metadata.cpId === undefined) {
            history.push(detailsPageUrl);
            return;
        }

        const isSubaccount = isSubaccountCp(getCpType(metadata.cpId));

        if (isPrimeBrokerUser && isSubaccount) {
            changePrimeBrokerViewType("subaccounts", true);
            history.push(detailsPageUrl.replace("/transactions", "/subaccountsViewTransactions"));
            return;
        }

        if (isPrimeBrokerUser && !isSubaccount) {
            changePrimeBrokerViewType("counterparties", true);
            history.push(detailsPageUrl);
            return;
        }

        if (isSubaccountUser) {
            history.push(detailsPageUrl.replace("/transactions", "/subaccountTransactions"));
            return;
        }

        history.push(detailsPageUrl);
    };

    return (
        <Container isRead={isRead}>
            <StatusWrapper>
                {!isRead && (
                    <Status onClick={() => markNotificationAsRead(id, isRead)}>
                        <CheckIcon style={{ opacity: 0 }} />
                    </Status>
                )}
            </StatusWrapper>
            <ContentWrapper onClick={handleContentClick}>
                <Message>{content} </Message>
                <Timestamp>{timeAgoString}</Timestamp>
            </ContentWrapper>
        </Container>
    );
};
