import { DropdownOption } from "@fm-frontend/uikit/src/components/v2";
import { POSITION_COUNTERPARTY_ID } from "const/position";
import { useSubaccounts } from "feature/subaccounts/api";
import { useMemo } from "react";
import { useCounterpartyLimitsSnapshot } from "store/hooks";
import { composeUsername } from "utils";
import { useIsAuthorizedTradingAvailable } from "./useIsAuthorizedTradingAvailable";

export const useClientOptionsForAuthorizedTrading = () => {
    const isAuthorizedTradingAvailable = useIsAuthorizedTradingAvailable();
    const { data: counterpartiesLimits, isLoading: isCounterpartiesLimitsLoading } =
        useCounterpartyLimitsSnapshot();
    const { subaccounts, isLoading: isSubaccountsLoading } = useSubaccounts();

    const clientOptionsForAuthorizedTrading = useMemo(() => {
        if (!isAuthorizedTradingAvailable) {
            return [];
        }

        const subTakersForAuthorizedTrading = subaccounts.filter(
            ({ info, status, type }) =>
                info.clientId &&
                type === "taker" &&
                status === "active" &&
                counterpartiesLimits.some(
                    (cpLimit) => cpLimit[POSITION_COUNTERPARTY_ID] === info.clientId,
                ),
        );
        return subTakersForAuthorizedTrading
            .map((value) => {
                return {
                    text: composeUsername(value.info.username),
                    value: value.info.clientId,
                } as DropdownOption<number>;
            })
            .sort((a, b) => a.text.localeCompare(b.text));
    }, [subaccounts, counterpartiesLimits, isAuthorizedTradingAvailable]);

    return {
        clientOptionsForAuthorizedTrading,
        isLoading: isSubaccountsLoading || isCounterpartiesLimitsLoading,
    };
};
