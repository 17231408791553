import { Accordion, HStack } from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { ROUTES } from "feature/app/router";
import { HistoryTab } from "feature/history/trades/hooks";
import { StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useState } from "react";
import { Spacer } from "style";
import styled from "styled-components";
import { LastRfqAccordionContent } from "./LastRfqAccordionContent";

const Caption = styled.span`
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

const LastRfqAccordionHeader = styled(HStack)`
    gap: 12px;
    align-items: center;
`;

const options: DropdownOption<StatusOfRfqHistoryItem>[] = [
    { text: "Executed", value: "COMMITTED" },
    { text: "Cancelled", value: "CANCELED" },
    { text: "Created", value: "CREATED" },
    { text: "Expired", value: "EXPIRED" },
];

export const LastRfqAccordion = () => {
    const [selectedStatuses, setSelectedStatuses] = useState<StatusOfRfqHistoryItem[]>([]);

    const header = (
        <LastRfqAccordionHeader>
            <Caption>Last Rfq</Caption>
            <div
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <MultipleDropdown
                    values={selectedStatuses}
                    onChange={setSelectedStatuses}
                    renderTrigger={(trigger) => (
                        <MultipleDropdown.Trigger {...trigger} size="small" variant="simple">
                            <MultipleDropdown.TriggerEssence
                                {...trigger}
                                option={trigger.selectedOptions}
                                size="small"
                            />
                        </MultipleDropdown.Trigger>
                    )}
                    options={options}
                    align="end"
                    caption="Status"
                    asFilter
                >
                    <MultipleDropdown.BasicSheet size="small" options={options} />
                </MultipleDropdown>
            </div>
            <Spacer />
        </LastRfqAccordionHeader>
    );

    return (
        <Accordion
            defaultExpanded
            header={header}
            content={<LastRfqAccordionContent selectedStatuses={selectedStatuses} />}
            detailsPageUrl={`${ROUTES.historyTrades}/${HistoryTab.RFQ}`}
        />
    );
};
