import {
    ClientId,
    EXPAND_TABLE_COLUMN_KEY,
    Flex,
    HStack,
    Icons,
    PSmall,
    PSmallBold,
    VStack,
} from "@fm-frontend/uikit";
import { getEnv, SIZE64 } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { CoinIcon } from "components/CoinIcon";
import { EmDash } from "const";
import {
    isExpandableRow,
    isGroupedByAssetExpandableRow,
    isGroupedByCpExpandableRow,
    isHRPClearingTimeInfoRow,
    isSubrow,
} from "feature/positions/utils";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import { Cell as CellType } from "react-table";
import { GroupBy } from "store/usePositionsGrouped";
import styled from "styled-components";
import { CopyableLabel } from "../../../TransactionLabel";
import { HRPClearingTimeInfoRow, PositionsTableRowData, Subrow } from "../types";

export const columnHelper = createColumnHelper<
    PositionsTableRowData | Subrow | HRPClearingTimeInfoRow
>();

const Cell = styled(VStack)<{
    $isNegative: boolean;
    $copyable?: boolean;
}>`
    > span {
        color: ${(p) => (p.$isNegative ? p.theme.colors.negative100 : "inherit")};
    }
`;

const ClearingBrokerContainer = styled(VStack)`
    padding: 2px 0;
`;

const PXSmall = styled(PSmall)`
    font-size: 10px;
    line-height: 12px;
`;

const ClearingTime = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const PointerCursor = styled(Flex)`
    cursor: pointer;
`;

const shiftedEmDash = (
    <Cell $isNegative={false} paddingRight={18}>
        {EmDash}
    </Cell>
);

const columns = [
    columnHelper.accessor((row) => row, {
        id: "price",
        cell: (rowData) => {
            const row = rowData.getValue();
            if (isExpandableRow(row) || isHRPClearingTimeInfoRow(row)) return null;
            const isNegative = row.price < 0;

            return (
                <Cell $isNegative={isNegative}>
                    <PSmall>{SIZE64.toFormattedDecimalString(row.price, 2)}</PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Price, $",
        meta: {
            cellStyleProps: {
                width: "100px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
            skipCell: (cell: CellType) => {
                return Boolean(cell.row.subRows?.length);
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "current",
        cell: (rowData) => {
            const { toFormattedDecimalStringByAsset } = useSize64AssetFormatHelpers();
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row) || isExpandableRow(row)) {
                return null;
            }

            if (row.value === 0n) {
                return shiftedEmDash;
            }

            const isNegative = row.value < 0;
            const positionCopyableText = SIZE64.toDecimalString(row.value);
            const positionText = toFormattedDecimalStringByAsset(row.value, row.asset);

            return (
                <Cell $isNegative={isNegative} $copyable>
                    <PSmall>
                        <CopyableLabel value={positionCopyableText} title={positionText} />
                    </PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Position",
        meta: {
            cellStyleProps: {
                width: "132px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: "0 18px 8px 0",
            },
            skipCell: (cell: CellType) => {
                return Boolean(cell.row.subRows?.length);
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "currentUsd",
        cell: (rowData) => {
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row)) {
                return null;
            }

            const usdPositionText = SIZE64.toFormattedDecimalString(row.valueUSD, 2);
            const usdPositionCopyableText = SIZE64.toDecimalString(row.valueUSD);

            if (isExpandableRow(row)) {
                if (row.valueUSD === 0n) {
                    return shiftedEmDash;
                }

                return (
                    <Cell $isNegative={row.valueUSD < 0}>
                        <PSmallBold>
                            <CopyableLabel
                                value={usdPositionCopyableText}
                                title={`$${usdPositionText}`}
                            />
                        </PSmallBold>
                    </Cell>
                );
            }

            if (row.value === 0n) {
                return shiftedEmDash;
            }

            const isNegative = row.value < 0;

            return (
                <Cell $isNegative={isNegative} $copyable>
                    <PSmall>
                        <CopyableLabel
                            value={usdPositionCopyableText}
                            title={`$${usdPositionText}`}
                        />
                    </PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Position, $",
        meta: {
            cellStyleProps: {
                width: "132px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: "0 18px 8px 0",
            },
        },
    }),
];

const { HRP_MASTER_ID, HRP_CLEARING_TIME } = getEnv();

export const getColumns = ({ groupBy }: { groupBy: GroupBy }) => [
    columnHelper.display({
        id: EXPAND_TABLE_COLUMN_KEY,
        header: ({ table }) => {
            const isAllRowsExpanded = table.getIsAllRowsExpanded();

            return (
                <PointerCursor onClick={table.getToggleAllRowsExpandedHandler()}>
                    {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
                </PointerCursor>
            );
        },
        cell: ({ row }) => {
            if (
                isHRPClearingTimeInfoRow(row.original) ||
                (isSubrow(row.original) &&
                    groupBy === "asset" &&
                    row.original.cpId === HRP_MASTER_ID)
            ) {
                return <Icons.WashBasin />;
            }

            return !row.getCanExpand() ? null : row.getIsExpanded() ? (
                <Icons.Collapse />
            ) : (
                <Icons.Expand />
            );
        },
        meta: {
            headerStyleProps: {
                width: "32px",
                maxWidth: "32px",
                padding: "0 0 8px 11px",
            },
            cellStyleProps: {
                width: "32px",
                maxWidth: "32px",
                padding: "0 0 0 11px",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "name",
        cell: (rowData) => {
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row)) {
                return (
                    <ClearingTime>
                        All positions will be cleared at {HRP_CLEARING_TIME}
                    </ClearingTime>
                );
            }

            if (isGroupedByAssetExpandableRow(row)) {
                const { asset } = row;

                return (
                    <HStack spacing={6} alignItems="center">
                        <CoinIcon coin={asset} size={16} />
                        <PSmallBold>{asset}</PSmallBold>
                    </HStack>
                );
            }

            if (isGroupedByCpExpandableRow(row)) {
                const { cpName, cpId } = row;

                if (cpId === HRP_MASTER_ID) {
                    return (
                        <ClearingBrokerContainer>
                            <HStack spacing={6} alignItems="center">
                                <PSmallBold>{cpName}</PSmallBold>
                                <ClientId id={cpId} />
                            </HStack>
                            <PXSmall>Clearing broker</PXSmall>
                        </ClearingBrokerContainer>
                    );
                }

                return (
                    <HStack spacing={6} alignItems="center">
                        <PSmallBold>{cpName}</PSmallBold>
                        <ClientId id={cpId} />
                    </HStack>
                );
            }

            if (isSubrow(row)) {
                const { cpName, cpId, asset } = row;

                if (groupBy === "counterparty") {
                    return (
                        <HStack spacing={6} alignItems="center">
                            <CoinIcon coin={asset} size={16} />
                            <PSmallBold>{asset}</PSmallBold>
                        </HStack>
                    );
                }

                if (groupBy === "asset") {
                    if (cpId === HRP_MASTER_ID) {
                        return (
                            <ClearingBrokerContainer>
                                <HStack spacing={6} alignItems="center">
                                    <PSmallBold>{cpName}</PSmallBold>
                                    <ClientId id={cpId} />
                                </HStack>
                                <PXSmall>Clearing broker</PXSmall>
                            </ClearingBrokerContainer>
                        );
                    }

                    return (
                        <HStack spacing={6} alignItems="center">
                            <PSmallBold>{cpName}</PSmallBold>
                            <ClientId id={cpId} />
                        </HStack>
                    );
                }
            }

            return null;
        },
        enableSorting: false,
        header: "Name",
        meta: {
            tdProps: (cell: CellType) => {
                if (cell.row.subRows?.length) {
                    return {
                        colSpan: 3,
                    };
                }
            },
        },
    }),
    ...columns,
];
