import { Accordion, HStack } from "@fm-frontend/uikit";
import { ROUTES } from "feature/app/router";
import { HistoryTab } from "feature/history/trades/hooks";
import styled from "styled-components";
import { LastFirmBookAccordionContent } from "./LastFirmBookAccordionContent";

const Caption = styled.span`
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

const LastFirmBookAccordionHeader = styled(HStack)`
    gap: 12px;
    align-items: center;
    ${Caption} {
        flex: 1;
    }
`;

export const LastFirmBookAccordion = () => {
    const header = (
        <LastFirmBookAccordionHeader>
            <Caption>Last trades</Caption>
        </LastFirmBookAccordionHeader>
    );

    return (
        <Accordion
            defaultExpanded
            header={header}
            content={<LastFirmBookAccordionContent />}
            detailsPageUrl={`${ROUTES.historyTrades}/${HistoryTab.FirmBook}`}
        />
    );
};
