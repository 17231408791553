import {
    ACTIONS_TABLE_COLUMN_KEY,
    IconButton,
    Icons,
    Skeleton,
    TableView,
    Tooltip,
} from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import {
    ColumnDef,
    createColumnHelper,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import { PositionsTableRowData, Subrow } from "feature/positions/types";
import { isSubrow } from "feature/positions/utils";
import { useCreatePrefilledTransactionOrRequest } from "hooks";
import React, { useMemo, useState } from "react";
import { GroupBy } from "store/usePositionsGrouped";
import { getColumns } from "./table";

const SKELETON_COUNT = 5;

type TableProps<T> = {
    data: T[];
    getSubRows?: (row: T, index: number) => T[];
    isLoading?: boolean;
    groupBy: GroupBy;
};

const columnHelper = createColumnHelper<PositionsTableRowData | Subrow>();

const { HRP_MASTER_ID } = getEnv();
export const PositionsTable: React.FC<TableProps<PositionsTableRowData>> = ({
    getSubRows,
    data,
    isLoading,
    groupBy,
}) => {
    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    const actionColumn = useMemo(
        () =>
            columnHelper.accessor((row) => row, {
                id: ACTIONS_TABLE_COLUMN_KEY,
                header: "",
                cell: (rowData) => {
                    const rowValue = rowData.getValue();

                    if (isSubrow(rowValue)) {
                        const { cpId, asset, value } = rowValue;

                        if (cpId === HRP_MASTER_ID) {
                            return null;
                        }

                        return (
                            <Tooltip content="Settle">
                                <IconButton
                                    variant="plain"
                                    Icon={rowValue.value < 0 ? Icons.ArrowUp : Icons.ArrowReply}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        createPrefilledTransactionOrRequest({
                                            cpId,
                                            asset,
                                            value,
                                        });
                                    }}
                                />
                            </Tooltip>
                        );
                    }

                    return null;
                },
                meta: {
                    cellStyleProps: {
                        width: "40px",
                        textAlign: "right",
                        padding: "0 8px 0 0",
                    },
                },
            }),
        [createPrefilledTransactionOrRequest],
    );

    const memoizedColumns = useMemo(() => {
        const tableColumns = getColumns({ groupBy });

        if (isLoading) {
            return tableColumns.map((column) => ({
                ...column,
                cell: <Skeleton height="16px" />,
            }));
        }

        return [...tableColumns, actionColumn];
    }, [isLoading, actionColumn, groupBy]);

    const memoizedData = useMemo(
        () => (isLoading ? Array(SKELETON_COUNT).fill({}) : data),
        [data, isLoading],
    );
    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns as ColumnDef<PositionsTableRowData, any>[],
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: getSubRows,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    });

    const isEmpty = !memoizedData.length && !isLoading;

    const handleRowClick = (row: Row<any>, id: string) => {
        if (id === ACTIONS_TABLE_COLUMN_KEY) {
            return;
        }

        const isExpandable = row.getCanExpand();

        if (isExpandable) {
            row.toggleExpanded();
        }
    };

    return <TableView table={table} onRowClick={handleRowClick} isEmpty={isEmpty} condensed />;
};
