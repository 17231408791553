import { HStack, Tab, TabContext, TabList, TabPanel } from "@fm-frontend/uikit";
import { SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { MobileLogo } from "components/icons";
import { TopMenuHeader } from "components/TopMenu";
import { UserMenuControl } from "components/UserMenuControl/UserMenuControl";
import { SEARCH_PARAMS } from "const";
import { RfqTradingPageContent } from "feature/rfq/TradingPageContent";
import { useAuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useClientOptionsForAuthorizedTrading } from "feature/trade/hooks/useClientOptionsForAuthorizedTrading";
import { useIsAuthorizedTradingAvailable } from "feature/trade/hooks/useIsAuthorizedTradingAvailable";
import { FirmBookTradingPageContent } from "feature/trade/trading";
import { ClientSelectTooltip } from "feature/trade/trading/ClientSelectTooltip";
import { LPManagement } from "feature/trade/trading/LPManagement";
import { NavigationContainer } from "feature/trade/trading/style";
import { EventContext, useEventEmitter } from "hooks/useEventEmitter";
import { useFavoriteInstruments } from "hooks/useFavoriteInstruments";
import { useFilterState } from "hooks/useFilterState";
import { useSelector } from "hooks/useSelector";
import { useEffect, useState } from "react";
import { usePrimeBrokerViewType, useUserType } from "store/hooks";
import { useIsRfqViewingEnabled } from "store/useRfqSettings";
import { Page, Spacer } from "style";
import styled from "styled-components";

export const createGroup = () => ({ base: new Set<string>(), quote: new Set<string>() });

const StyledMobileLogo = styled(MobileLogo)`
    margin-right: 8px;
`;

const useIsLpManagementAvailable = () => {
    const userType = useUserType();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (
        (userType === "primeBroker" && primeBrokerViewType === "subaccounts") ||
        userType === "taker"
    );
};

const useSelectedPageState = () => {
    return useFilterState<"firmBook" | "rfq">("trading", SEARCH_PARAMS.tradingType, "firmBook");
};

export const PageTrading = () => {
    const { isMobile } = useSelector((state) => state.app);
    const isLpManagementAvailable = useIsLpManagementAvailable();
    const [selectedPage, setSelectedPage] = useSelectedPageState();
    const isRfqViewingEnabled = useIsRfqViewingEnabled();
    const {
        clientOptionsForAuthorizedTrading,
        isLoading: isClientOptionsForAuthorizedTradingLoading,
    } = useClientOptionsForAuthorizedTrading();
    const isAuthorizedTradingAvailable = useIsAuthorizedTradingAvailable();
    const isClientSelectAvailable =
        isAuthorizedTradingAvailable &&
        selectedPage === "firmBook" &&
        clientOptionsForAuthorizedTrading.length > 0 &&
        !isClientOptionsForAuthorizedTradingLoading;
    const eventEmitter = useEventEmitter();
    const { allFavoriteInstruments } = useFavoriteInstruments();

    const [isAuthorizedClientSelectHintActive, setIsAuthorizedClientSelectHintActive] =
        useState(false);

    useEffect(() => {
        const shouldHintBeActive = Object.values(allFavoriteInstruments ?? {}).every(
            ({ favoriteInstruments = [] }) => favoriteInstruments.length === 0,
        );

        setIsAuthorizedClientSelectHintActive(shouldHintBeActive);
    }, [allFavoriteInstruments]);

    const { authorizedClientId, setAuthorizedClientId } = useAuthorizedClientId();

    return (
        <Page noBottomSpace>
            <EventContext.Provider value={eventEmitter}>
                <TabContext
                    value={selectedPage}
                    handleClick={setSelectedPage as (group: string) => void}
                >
                    <TopMenuHeader>
                        {isMobile && <StyledMobileLogo />}
                        <NavigationContainer>
                            <div style={{ minWidth: "274px" }}>
                                <TabList size="large">
                                    <Tab title="Firm book" value="firmBook" key="firmBook" />
                                    <Tab
                                        title="RFQ"
                                        value="rfq"
                                        key="rfq"
                                        isAvailable={isRfqViewingEnabled}
                                    />
                                </TabList>
                            </div>
                            {isClientSelectAvailable && (
                                <ClientSelectTooltip
                                    initialActive={isAuthorizedClientSelectHintActive}
                                    onClose={() => setIsAuthorizedClientSelectHintActive(false)}
                                >
                                    <HStack
                                        height="40px"
                                        minWidth="270px"
                                        paddingLeft={8}
                                        onClick={
                                            isAuthorizedClientSelectHintActive
                                                ? () => setIsAuthorizedClientSelectHintActive(false)
                                                : undefined
                                        }
                                    >
                                        <SingleDropdown
                                            value={authorizedClientId ?? undefined}
                                            onChange={setAuthorizedClientId}
                                            renderTrigger={(trigger) => (
                                                <SingleDropdown.Trigger
                                                    {...trigger}
                                                    size="large"
                                                    variant="simple"
                                                >
                                                    <CounterpartyTriggerEssence
                                                        {...trigger}
                                                        option={trigger.selectedOption}
                                                        size="large"
                                                    />
                                                </SingleDropdown.Trigger>
                                            )}
                                            options={clientOptionsForAuthorizedTrading}
                                            placeholder="Select"
                                            caption="Client"
                                            fullWidth
                                        >
                                            <CounterpartiesSheet
                                                size="medium"
                                                options={clientOptionsForAuthorizedTrading}
                                                Dropdown={SingleDropdown}
                                            />
                                        </SingleDropdown>
                                    </HStack>
                                </ClientSelectTooltip>
                            )}
                        </NavigationContainer>
                        {!isMobile && <Spacer />}
                        {isLpManagementAvailable && <LPManagement key="lp-management" />}
                        <UserMenuControl />
                    </TopMenuHeader>

                    <TabPanel value="firmBook" overflow>
                        <FirmBookTradingPageContent />
                    </TabPanel>
                    <TabPanel value="rfq" isAvailable={isRfqViewingEnabled}>
                        <RfqTradingPageContent />
                    </TabPanel>
                </TabContext>
            </EventContext.Provider>
        </Page>
    );
};
