import { Accordion, HStack, PSmall, Tab, TabContext, TabList } from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { ROUTES } from "feature/app/router";
import { useState } from "react";
import { GroupBy, usePositionsTableData } from "store/usePositionsGrouped";
import { Spacer } from "style";
import styled from "styled-components";
import { PositionsAccordionContent } from "./PositionsAccordionContent";

const Caption = styled.span`
    color: ${(p) => p.theme.colors.ui72};
    text-transform: uppercase;
`;

const TabsContainer = styled.div`
    max-width: 120px;
`;

const PositionsAccordionHeader = styled(HStack)`
    gap: 12px;
    align-items: center;
`;

export const PositionsAccordion = () => {
    const [groupBy, setGroupBy] = useState<GroupBy>("counterparty");
    const { totalUSD, groupedPositionsData, isLoading } = usePositionsTableData({
        groupBy,
        includeSettlementOrders: true,
    });
    const [expanded, setExpanded] = useState(true);

    const header = (
        <PositionsAccordionHeader>
            <Caption>Positions</Caption>
            {expanded && (
                <TabsContainer
                    onClick={(ev) => {
                        ev.stopPropagation();
                    }}
                >
                    <TabContext value={groupBy} handleClick={setGroupBy}>
                        <TabList size="small">
                            <Tab title="CPs" value="counterparty" key="counterparty" />
                            <Tab title="Assets" value="asset" key="asset" />
                        </TabList>
                    </TabContext>
                </TabsContainer>
            )}
            <Spacer />
            <PSmall>Total: ${SIZE64.toFormattedDecimalString(totalUSD, 2)}</PSmall>
        </PositionsAccordionHeader>
    );

    return (
        <Accordion
            header={header}
            defaultExpanded={expanded}
            onExpandedChange={setExpanded}
            content={
                <PositionsAccordionContent
                    groupBy={groupBy}
                    groupedPositionsData={groupedPositionsData}
                    isLoading={isLoading}
                />
            }
            detailsPageUrl={ROUTES.positions}
        />
    );
};
