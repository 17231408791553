import { getEnv } from "@fm-frontend/utils";
import { useSelector } from "hooks";
import { IRootState } from "store";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "store/hooks";

const { AUTHORIZED_TRADING_MASTERS = [] } = getEnv();

export const useIsAuthorizedTradingAvailable = () => {
    const clientId = useSelector((state: IRootState) => state.authentication.clientData?.clientId);
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    const basicCondition = isPrimeBrokerUser && primeBrokerViewType === "subaccounts" && clientId;

    if (AUTHORIZED_TRADING_MASTERS.length === 0) {
        return basicCondition;
    }

    return basicCondition && AUTHORIZED_TRADING_MASTERS.includes(clientId);
};
