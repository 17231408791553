import { post } from "api";
import useSWR from "swr";
import { PreparedDeal, prepareDeal } from "types";

const getAuthorizedDealsHistoryApi = (
    authorizedClientId: number,
    filter: {
        limit: number;
    },
) =>
    post("authorized/dealHistory", {
        clientId: authorizedClientId,
        limit: filter.limit,
    }).then((data) => (Array.isArray(data) ? data.map(prepareDeal) : []));

type SWRKey = string;

export const useAuthorizedLastTradesHistorySWRKey = (limit: number, cpId?: number | null) => {
    return `useAuthorizedDealsHistory-${limit}-${cpId}`;
};

export const useAuthorizedLastTradesHistory = (
    limit: number,
    authorizedClientId?: number | null,
) => {
    const key = useAuthorizedLastTradesHistorySWRKey(limit, authorizedClientId);

    return useSWR<PreparedDeal[], undefined, SWRKey>(
        key,
        () => {
            if (!authorizedClientId) {
                throw new Error("Authorized client ID is not provided");
            }

            return getAuthorizedDealsHistoryApi(authorizedClientId, {
                limit: limit,
            });
        },
        {
            fallbackData: [],
        },
    );
};
